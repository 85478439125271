/// <reference types="@types/google.accounts" />

import { Component, OnInit } from '@angular/core';
import {AuthService} from "../auth.service";
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import {Router} from  '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit{
  private window: Window;

  constructor(@Inject(DOCUMENT) private document: Document,
              private  authservice: AuthService,
              private router: Router) {
    this.window = this.document.defaultView!;
  }

  ngOnInit(): void {
    const that  = this;
    google.accounts.id.initialize({
      client_id: '611354135457-dvd6f4mh09soree3in9vtesb99o67571.apps.googleusercontent.com',
      callback: (credResp: google.accounts.id.CredentialResponse) => {
        that.authservice.setSession(credResp.credential);
        that.router.navigate(["/"]);
      }
    });

    const parent = document.getElementById('google_btn')!;
    google.accounts.id.renderButton(parent, {type: "standard", theme: "filled_blue",width:"400px"});
    }
}


declare global {
  interface Window {
    onSignIn: (credResp:any) => void;
  }
}
