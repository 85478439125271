import { Component, OnInit } from '@angular/core';
import {StorySummary} from "../../../generated/OnlyFanFic";
import {OnlyFanFicService} from "../only-fan-fic.service";

@Component({
  selector: 'app-story-list',
  templateUrl: './story-list.component.html',
  styleUrls: ['./story-list.component.sass']
})
export class StoryListComponent implements  OnInit{
  summaries: StorySummary[] = [];

  constructor(private onlyFanFicService: OnlyFanFicService) { }

  ngOnInit() {
    console.log("Sending request");
    this.onlyFanFicService.getStorySummaries().subscribe(resp => {
      this.summaries = resp.stories;
      console.log("Got data ", resp);
    })
  }
}
