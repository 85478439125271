<mat-toolbar color="primary">
  <span>OnlyFanFic </span>
    <a [routerLink]="['/browse']" color="accent">Browse</a>
  <span>
  <form>
  <mat-form-field id="search-form-field">
    <mat-icon matPrefix color="primary">search</mat-icon>
    <input matInput >
  </mat-form-field>
  </form>
    </span>
  <span class="fill-remaining-space"></span>
  <div *ngIf="authService.isLoggedIn(); else elseBlock">
    <span class="material-icons">notifications</span>
    <span class="material-icons">account_circle</span>
    Welcome {{ authService.getUser() }}!
  </div>
  <ng-template #elseBlock><a [routerLink]="['/login']" mat-raised-button>Login</a></ng-template>


</mat-toolbar>
<div id="router-container">
  <router-outlet></router-outlet>
</div>
