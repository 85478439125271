import { Injectable } from '@angular/core';
import * as moment from "moment";
import * as jwt from 'jose';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public setSession(token: string) {
    const decoded = jwt.decodeJwt(token)!
    const expiresAt = moment.utc(decoded.exp! * 1000);

    console.log("decoded:", decoded);
    localStorage.setItem('id_token', token);
    localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()) );
  }

  logout() {
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
  }

  public isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration() {
    const expiration = localStorage.getItem("expires_at");
    const expiresAt = JSON.parse(expiration!);
    return moment(expiresAt);
  }

  getUser(): string {
    const decoded = jwt.decodeJwt(localStorage.getItem("id_token")!);
    return decoded['given_name'] as string;
  }

  public getAuthHeader() :Record<string, string> {
    if (this.isLoggedOut()){
      console.log("no auth header")
      return {};
    }
    let id_token = localStorage.getItem("id_token")!;
    return {'Authorization': `Bearer ${id_token}`}
  }
}
