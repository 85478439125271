import { Component, Input } from '@angular/core';
import {StorySummary} from "../../../generated/OnlyFanFic";

@Component({
  selector: 'app-story-box',
  templateUrl: './story-box.component.html',
  styleUrls: ['./story-box.component.sass']
})
export class StoryBoxComponent {
  @Input() summary: StorySummary = StorySummary.create();
}
