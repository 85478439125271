/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "com.onlyfanfic";

export interface ListStoriesRequest {
}

export interface ListStoriesResponse {
  stories: StorySummary[];
}

export interface StorySummary {
  title: string;
  author: string;
  published: Date | undefined;
}

function createBaseListStoriesRequest(): ListStoriesRequest {
  return {};
}

export const ListStoriesRequest = {
  encode(_: ListStoriesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListStoriesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListStoriesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListStoriesRequest {
    return {};
  },

  toJSON(_: ListStoriesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListStoriesRequest>, I>>(base?: I): ListStoriesRequest {
    return ListStoriesRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ListStoriesRequest>, I>>(_: I): ListStoriesRequest {
    const message = createBaseListStoriesRequest();
    return message;
  },
};

function createBaseListStoriesResponse(): ListStoriesResponse {
  return { stories: [] };
}

export const ListStoriesResponse = {
  encode(message: ListStoriesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.stories) {
      StorySummary.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListStoriesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListStoriesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.stories.push(StorySummary.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListStoriesResponse {
    return { stories: Array.isArray(object?.stories) ? object.stories.map((e: any) => StorySummary.fromJSON(e)) : [] };
  },

  toJSON(message: ListStoriesResponse): unknown {
    const obj: any = {};
    if (message.stories) {
      obj.stories = message.stories.map((e) => e ? StorySummary.toJSON(e) : undefined);
    } else {
      obj.stories = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListStoriesResponse>, I>>(base?: I): ListStoriesResponse {
    return ListStoriesResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ListStoriesResponse>, I>>(object: I): ListStoriesResponse {
    const message = createBaseListStoriesResponse();
    message.stories = object.stories?.map((e) => StorySummary.fromPartial(e)) || [];
    return message;
  },
};

function createBaseStorySummary(): StorySummary {
  return { title: "", author: "", published: undefined };
}

export const StorySummary = {
  encode(message: StorySummary, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.title !== "") {
      writer.uint32(10).string(message.title);
    }
    if (message.author !== "") {
      writer.uint32(18).string(message.author);
    }
    if (message.published !== undefined) {
      Timestamp.encode(toTimestamp(message.published), writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StorySummary {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStorySummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.title = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.author = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.published = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StorySummary {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      author: isSet(object.author) ? String(object.author) : "",
      published: isSet(object.published) ? fromJsonTimestamp(object.published) : undefined,
    };
  },

  toJSON(message: StorySummary): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.author !== undefined && (obj.author = message.author);
    message.published !== undefined && (obj.published = message.published.toISOString());
    return obj;
  },

  create<I extends Exact<DeepPartial<StorySummary>, I>>(base?: I): StorySummary {
    return StorySummary.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StorySummary>, I>>(object: I): StorySummary {
    const message = createBaseStorySummary();
    message.title = object.title ?? "";
    message.author = object.author ?? "";
    message.published = object.published ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
