import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {ListStoriesResponse} from "../../generated/OnlyFanFic";
import {map, Observable } from 'rxjs';
import {AuthService} from "./auth.service";


@Injectable({
  providedIn: 'root'
})
export class OnlyFanFicService {

  constructor(private httpClient: HttpClient, private authService: AuthService) { }

  getStorySummaries():Observable<ListStoriesResponse>{
    const auth_headers = this.authService.getAuthHeader();
    console.log("got auth header: ", auth_headers)
    const headers = new HttpHeaders(auth_headers);
    return this.httpClient.get("/api/v1/story/summary", {headers: headers}).pipe(map(resp =>ListStoriesResponse.fromJSON(resp)));
  }
}
